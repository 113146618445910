/* eslint-disable */
import AppHeader from "@/components/partials/Header/AppHeader.vue";
import AppFooter from "@/components/partials/Footer/AppFooter.vue";
import SideBarMenu from "@/components/partials/Sidebar/SideBarMenu.vue";
import MainView from "@/mainView.vue";

// import views for router
import Login from "@/views/login.vue";

const routes = [
  // {
  //   path: '/',
  //   name: 'home'
  //   component: () => import('@/components/HelloWorld.vue')
  // }
  {
    path: "/",
    redirect: "/inicio",
    component: MainView,
    children: [
      {
        path: "/login",
        name: "login",
        components: {
          // appHeader: AppHeader,
          // appSidebar: SideBarMenu,
          appContent: () => import("../views/login.vue"),
          appFooter: AppFooter,
        },
        meta: {
          requiresAuth: false,
          title: "Login",
          description: "Area de acesso ao sistema",
          rules: {
            required: false,
            name: "login",
            redirect: "login",
          },
        },
      },
      // TODO: forgot password
      {
        path: "/example",
        name: "example",
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/example.vue"),
          appFooter: AppFooter,
        },
        meta: {
          requiresAuth: true,
          title: "Exemplo",
          description: "Exemplo de página",
          rules: {
            required: false,
            name: "example",
            redirect: "example",
          },
        },
      },
      {
        path: "/inicio",
        name: "home",
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/home.vue"),
          appFooter: AppFooter,
        },
        meta: {
          requiresAuth: true,
          title: "Inicio",
          description: "Página inicial do sistema",
          rules: {
            required: false,
            name: "home",
            redirect: "home",
          },
        },
      },
      {
        path: "/empresas",
        name: "company",
        redirect: { name: "companyList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/company.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "companyList",
            component: () =>
              import("@/components/company/CompanyList/CompanyList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Empresas",
              description: "Lista de clientes cadastrados",
              rules: {
                required: true,
                name: "companyList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "companyCreate",
            component: () =>
              import("@/components/company/CompanyCreate/CompanyCreate.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Empresa",
              description: "Criar novo cliente e dados de acesso",
              rules: {
                required: true,
                name: "companyCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "companyEdit",
            component: () =>
              import("@/components/company/CompanyEdit/CompanyEdit.vue"),
            meta: {
              requiresAuth: true,
              title: "Editar Empresa",
              description: "Editar dados do cliente e dados de acesso",
              rules: {
                required: true,
                name: "companyEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/relatorios-conta",
        name: "accountReports",
        redirect: { name: "accountReportsGeneric" },
        components: {
          appContent: () => import("../views/accountReports.vue"),
        },
        children: [
          {
            path: ":accountId/:path/:startDate?/:endDate?",
            name: "accountReportsGeneric",
            component: () =>
              import("@/components/accountReports/ReportGenericTable.vue"),
            meta: {
              requiresAuth: true,
              title: "Relatórios da Conta",
              description: "Exibir relatórios da conta",
            },
            rules: {
              required: true,
              name: "companyEdit",
              redirect: "userHistory",
            },
          },
        ],
      },
      {
        path: "/stock",
        name: "productIdsList",
        redirect: { name: "productIdsList" },
        components: {
          appContent: () => import("../views/productIdsList.vue"),
        },
        children: [
          {
            path: ":id",
            name: "productIdsList",
            component: () =>
              import("@/components/accountReports/ReportGenericTable.vue"),
            meta: {
              requiresAuth: true,
              title: "Relatórios da Conta",
              description: "Exibir relatórios da conta",
            },
            rules: {
              required: true,
              name: "companyEdit",
              redirect: "userHistory",
            },
          },
        ],
      },
      {
        path: "/receipt",
        name: "receipt",
        redirect: { name: "receiptGeneric" },
        components: {
          appContent: () => import("../views/receipt.vue"),
        },
        children: [
          {
            path: ":path",
            name: "receiptGeneric",
            component: () =>
              import("@/components/receipt/ReceiptRechargeMobile.vue"),
            props: true,
            meta: {
              requiresAuth: true,
              title: "Comprovantes",
              description: "Exibir Comprovante",
            },
            rules: {
              required: true,
              name: "companyEdit",
              redirect: "userHistory",
            },
          },
        ],
      },
      {
        path: "/campanhas",
        name: "campaign",
        redirect: { name: "campaignList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/campaign.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "campaignList",
            component: () =>
              import("@/components/campaign/CampaignList/CampaignList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Ordens de Serviço",
              description: "Listagem de ordens de serviço cadastradas",
              rules: {
                required: true,
                name: "campaignList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/criar",
            name: "campaignCreate",
            component: () =>
              import(
                "@/components/campaign/CampaignCreation/CampaignCreation.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Ordem de Serviço",
              description: "Criar nova ordem de serviço para o cliente",
              rules: {
                required: true,
                name: "campaignCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "campaignDetails",
            component: () =>
              import(
                "@/components/campaign/CampaignDetails/CampaignDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes da Ordem de Serviço",
              description:
                "Ver detalhes dos dados da ordem de serviço do cliente",
              rules: {
                required: true,
                name: "campaignDetails",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "campaignEdit",
            component: () =>
              import("@/components/campaign/CampaignEdit/CampaignEdit.vue"),
            meta: {
              requiresAuth: true,
              title: "Edição da Ordem de Serviço",
              description: "Editar dos dados da ordem de serviço do cliente",
              rules: {
                required: true,
                name: "campaignEdit",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/personalizar",
            name: "campaignPersonalize",
            component: () =>
              import(
                "@/components/campaign/CampaignPersonalize/CampaignPersonalize.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Personalizar Ordem de Serviço",
              description: "Personalizar a ordem de serviço do cliente",
              rules: {
                required: true,
                name: "campaignPersonalize",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/extrato",
        name: "operationStatement",
        redirect: { name: "operationStatementList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/operationStatement.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "operationStatementList",
            component: () =>
              import("@/components/operationStatement/operationStatementList/OperationStatementList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Extratos",
              description: "Listagem de Extratos de operações",
              rules: {
                required: true,
                name: "operationStatementList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/extrato",
        name: "accountStatement",
        redirect: { name: "accountStatementGeneric" },
        components: {
          appContent: () => import("../views/accountStatement.vue"),
        },
        children: [
          {
            path: ":companyId/:path/:startDate?/:endDate?",
            name: "accountStatementGeneric",
            component: () =>
              import("@/components/accountStatement/StatementGenericTable.vue"),
            meta: {
              requiresAuth: true,
              title: "Relatórios da Conta",
              description: "Exibir relatórios da conta",
            },
            rules: {
              required: true,
              name: "companyEdit",
              redirect: "userHistory",
            },
          },
        ],
      },
      {
        path: "/transações",
        name: "transactions",
        redirect: { name: "transactionsList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/transactions.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "transactionsList",
            component: () =>
              import("@/components/transactions/TableList/TableList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Transações",
              description: "Listagem de transações cadastradas",
              rules: {
                required: true,
                name: "transactionsList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/contratos",
        name: "company list",
        redirect: { name: "CompanyList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/contract.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "empresas/lista",
            name: "contractCompanyList",
            component: () =>
              import("@/components/contract/CompanyList/CompanyList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Empresas",
              description: "Listagem de empresas",
              rules: {
                required: true,
                name: "contractCompanyList",
                redirect: "home",
              },
            },
          },
          // {
          //   path: ':id/lista',
          //   name: 'contractList',
          //   component: () => import('@/components/contract/ContractList/ContractList.vue'),
          //   meta: {
          //     requiresAuth: true,
          //     title: 'Lista de Contratos',
          //     description: 'Listagem de contratos cadastrados',
          //     rules: {
          //       required: true,
          //       name: 'contractList',
          //       redirect: 'home'
          //     }
          //   }
          // }
          {
            path: "criar",
            name: "contractCreate",
            component: () =>
              import(
                "@/components/contract/ContractCreate/FormCreateContract.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Contrato",
              description: "Criar novo contrato para o cliente",
              rules: {
                required: true,
                name: "contractCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "contractEdit",
            component: () =>
              import("@/components/contract/ContractEdit/FormEditContract.vue"),
            meta: {
              requiresAuth: true,
              title: "Editar Contrato",
              description: "Editar dados do contrato do cliente",
              rules: {
                required: true,
                name: "contractEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/aditivos",
        name: "additive",
        redirect: { name: "additiveList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/additive.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "additiveList",
            meta: {
              requiresAuth: true,
              title: "Lista de Aditivos",
              description: "Listagem de aditivos cadastrados",
              rules: {
                required: true,
                name: "additiveList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id?/criar",
            name: "additiveCreate",
            meta: {
              requiresAuth: true,
              title: "Criar Aditivo",
              description: "Criar novo aditivo para o cliente",
              rules: {
                required: true,
                name: "additiveCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "additiveEdit",
            meta: {
              requiresAuth: true,
              title: "Editar Aditivo",
              description: "Editar dados do aditivo do cliente",
              rules: {
                required: true,
                name: "additiveEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/representantes",
        name: "representative",
        redirect: { name: "representativeList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/representative.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "representativeList",
            meta: {
              requiresAuth: true,
              title: "Lista de Representantes",
              description: "Listagem de representantes cadastrados",
              rules: {
                required: true,
                name: "representativeList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "representativeCreate",
            meta: {
              requiresAuth: true,
              title: "Criar Representante",
              description:
                "Criar novo representante para o cliente ou um interno da empresa (comercial)",
              rules: {
                required: true,
                name: "representativeCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "representativeEdit",
            meta: {
              requiresAuth: true,
              title: "Editar Representante",
              description: "Editar dados do representante do cliente",
              rules: {
                required: true,
                name: "representativeEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/representantes-premiados",
        name: "awardedUser",
        redirect: { name: "AwardedUserList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/awardedUser.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "AwardedUserList",
            component: () =>
              import(
                "../components/awardedUser/AwardedUserList/AwardedUserList.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Lista de Premiados",
              description: "Listagem de premiados",
              rules: {
                required: true,
                name: "AwardedUserList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "AwardedUserCreate",
            component: () =>
              import("../components/global/Users/UsersCreate/UsersCreate.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Premiado",
              description: "Criar novo premiado",
              rules: {
                required: true,
                name: "AwardedUserCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "AwardedUserEdit",
            component: () =>
              import("../components/global/Users/UsersEdit/UsersEdit.vue"),
            meta: {
              requiresAuth: true,
              title: "Editar Premiado",
              description: "Editar dados do premiado",
              rules: {
                required: true,
                name: "userEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/usuarios-onlypay",
        name: "onlypayUsers",
        redirect: { name: "OnlypayUsersList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/onlypayUsers.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "OnlypayUsersList",
            component: () =>
              import(
                "../components/onlypayUsers/OnlypayUsersList/OnlypayUsersList.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Lista de Usuários",
              description: "Listagem de usuários com acesso ao sistema",
              rules: {
                required: true,
                name: "OnlypayUsersList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "OnlypayUsersCreate",
            component: () =>
              import("../components/global/Users/UsersCreate/UsersCreate.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Usuário",
              description: "Criar novo usuário para gerenciar o sistema",
              rules: {
                required: true,
                name: "OnlypayUsersCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "OnlypayUsersEdit",
            component: () =>
              import("../components/global/Users/UsersEdit/UsersEdit.vue"),
            meta: {
              requiresAuth: true,
              title: "Editar Usuário",
              description: "Editar dados do usuário do sistema",
              rules: {
                required: true,
                name: "OnlypayUsersEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/tipo-produto",
        name: "productType",
        redirect: { name: "productTypeList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/productType.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "productTypeList",
            component: () => import("@/components/productType/TableList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Tipos de Produto",
              description: "Listagem de tipos de produto cadastrados",
              rules: {
                required: true,
                name: "productTypeList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "productTypeCreate",
            component: () =>
              import("@/components/productType/FormProductType.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Tipo de Produto",
              description: "Criar novo tipo de produto para o cliente",
              rules: {
                required: true,
                name: "productTypeCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "productTypeEdit",
            component: () =>
              import("@/components/productType/FormProductType.vue"),
            meta: {
              requiresAuth: true,
              title: "Editar Tipo de Produto",
              description: "Editar dados do tipo de produto do cliente",
              rules: {
                required: true,
                name: "productTypeEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/produtos",
        name: "product",
        redirect: { name: "productList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/product.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "productList",
            meta: {
              requiresAuth: true,
              title: "Lista de Produtos",
              description: "Listagem de produtos cadastrados",
              rules: {
                required: true,
                name: "productList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "productCreate",
            meta: {
              requiresAuth: true,
              title: "Criar Produto",
              description: "Criar novo produto para o cliente",
              rules: {
                required: true,
                name: "productCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "productEdit",
            meta: {
              requiresAuth: true,
              title: "Editar Produto",
              description: "Editar dados do produto do cliente",
              rules: {
                required: true,
                name: "productEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/pedidos",
        name: "orderAwarded",
        redirect: { name: "orderAwardedList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/orderAwarded.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "orderAwardedList",
            component: () =>
              import("@/components/orderAwarded/TableList/TableList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Pedido",
              description: "Listar pedidos para o cliente",
              rules: {
                required: true,
                name: "orderAwardedList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "orderDetails",
            component: () =>
              import("@/components/orderAwarded/OrderDetails/OrderDetails.vue"),
            meta: {
              requiresAuth: true,
              title: "Detalhes do Pedido",
              description: "Detalhes do pedidos gerado para o cliente",
              rules: {
                required: true,
                name: "orderDetails",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/integracoes",
            name: "orderIntegrations",
            component: () =>
              import("@/components/orderAwarded/OrderIntegrations/OrderIntegrations.vue"),
            meta: {
              requiresAuth: true,
              title: "Integrações do Pedido",
              description: "Integrações do pedidos gerado para o cliente",
              rules: {
                required: true,
                name: "orderIntegrations",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "orderCreate",
            meta: {
              requiresAuth: true,
              title: "Criar Pedido",
              description: "Criar novo pedido para o cliente",
              rules: {
                required: true,
                name: "orderCreate",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/reserva-de-cartoes",
        name: "cardsReserve",
        redirect: { name: "cardsReserveList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/cardsReserve.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "cardsReserveList",
            meta: {
              requiresAuth: true,
              title: "Listar Reserva de Cartões",
              description: "Listar Reserva de Cartões para o cliente",
              rules: {
                required: true,
                name: "cardsReserveList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "cardsReserveDetails",
            component: () =>
              import(
                "@/components/cardsReserve/CardsReserveDetails/CardsReserveDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes da Reserva de Cartão",
              description:
                "Detalhes da reserva de cartão gerada para o cliente",
              rules: {
                required: true,
                name: "cardsReserveDetails",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "cardsReserveCreate",
            meta: {
              requiresAuth: true,
              title: "Criar Pedido",
              description: "Criar nova reserva de cartão para o cliente",
              rules: {
                required: true,
                name: "cardsReserveCreate",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/recargas",
        name: "recharges",
        redirect: { name: "rechargesList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/recharges.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "rechargesList",
            component: () =>
              import("@/components/recharges/rechargesList/rechargesList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Pedido",
              description: "Listar pedidos para o cliente",
              rules: {
                required: true,
                name: "rechargesList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "rechargeDetails",
            component: () =>
              import(
                "@/components/recharges/rechargeDetails/rechargeDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes do Pedido",
              description: "Detalhes do pedidos gerado para o cliente",
              rules: {
                required: true,
                name: "orderDetails",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/notas-fiscais",
        name: "invoices",
        redirect: { name: "invoicesList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/invoices.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "pedidos-de-nota-fiscal/lista",
            name: "invoicesRequestList",
            component: () =>
              import(
                "@/components/invoices/invoicesRequest/InvoicesRequestList/InvoicesRequestList.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Listar Pedidos Notas Fiscais",
              description: "Listar pedidos de notas fiscais para o cliente",
              rules: {
                required: true,
                name: "invoicesRequestList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/criar",
            name: "invoicesRequestCreate",
            component: () =>
              import(
                "@/components/invoices/invoicesRequest/InvoicesRequestCreation/InvoicesRequestCreation.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Pedidos Notas Fiscais",
              description: "Criar pedidos de notas fiscais para o cliente",
              rules: {
                required: true,
                name: "invoicesRequestCreate",
                redirect: "home",
              },
            },
          },
          // {
          //   path: ":id/detalhes",
          //   name: "orderDetails",
          //   component: () =>
          //     import("@/components/orderAwarded/OrderDetails/OrderDetails.vue"),
          //   meta: {
          //     requiresAuth: true,
          //     title: "Detalhes do Pedido",
          //     description: "Detalhes do pedidos gerado para o cliente",
          //     rules: {
          //       required: true,
          //       name: "orderDetails",
          //       redirect: "home",
          //     },
          //   },
          // },
          {
            path: "lista",
            name: "invoicesList",
            component: () => import("@/components/invoices/TableList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Notas Fiscais",
              description: "Listar notas fiscais para o cliente",
              rules: {
                required: true,
                name: "invoicesList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "invoiceCreate",
            component: () => import("@/components/invoices/FormInvoice.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Nota Fiscal",
              description: "Criar nova nota fiscal para o cliente",
              rules: {
                required: true,
                name: "invoiceCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "invoiceUpdate",
            component: () =>
              import(
                "@/components/invoices/UpdateInvoice/FormUpdateInvoice/FormUpdateInvoice.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Editar Nota Fiscal",
              description: "Editar uma nota fiscal para o cliente",
              rules: {
                required: true,
                name: "invoiceUpdate",
                redirect: "home",
              },
            },
          },
          {
            path: "pedido",
            name: "invoiceOrder",
            component: () =>
              import(
                "@/components/invoices/UpdateInvoice/FormUpdateInvoice/InvoiceOrder/InvoiceOrder.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Ver Pedido da Nota Fiscal",
              description:
                "Mostrar pedido vinculado a nota fiscal para o cliente",
              rules: {
                required: true,
                name: "invoiceOrder",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/ntokens",
        name: "ntoken",
        redirect: { name: "nTokenRequest" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/ntokens.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "nTokenRequest",
            meta: {
              requiresAuth: true,
              title: "Pedidos de NToken",
              description: "Listagem de pedidos de NToken",
              rules: {
                required: true,
                name: "nTokenRequest",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/cartoes",
        name: "card",
        redirect: { name: "cardsList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/card.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "cardsList",
            beforeEnter: (to, from, next) => {
              to.meta.routerFrom = from.name;
              next();
            },
            component: () =>
              import("@/components/card/CardsList/CardsList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Cartões",
              description: "Listar cartões para o cliente",
              rules: {
                required: true,
                name: "requestCard",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "cardDetails",
            component: () =>
              import("@/components/card/CardDetails/CardDetails.vue"),
            meta: {
              requiresAuth: true,
              title: "Detalhes do Cartão",
              description: "Detalhes do cartão",
              rules: {
                required: true,
                name: "cardDetails",
                redirect: "home",
              },
            },
          },
          {
            path: "transferencia-cartoes",
            name: "cardsTransfer",
            component: () =>
              import("@/components/card/TransferCard/Transfer.vue"),
            meta: {
              requiresAuth: true,
              title: "Transferência entre cartões",
              description: "Transferir saldo entre cartões",
              rules: {
                required: true,
                name: "transferBalance",
                redirect: "home",
              },
            },
          },
          {
            path: "segunda-via",
            name: "duplicateCard",
            component: () =>
              import("@/components/card/DuplicateCard/DuplicateCard.vue"),
            meta: {
              requiresAuth: true,
              title: "2ª via de cartão",
              description: "Tela 2ª via de cartão",
              rules: {
                required: true,
                name: "duplicateCard",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/aprovacoes",
        name: "approvals",
        redirect: { name: "approvalsList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/approvals.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "approvalsList",
            component: () =>
              import("@/components/approvals/ApprovalsList/ApprovalsList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Aprovações",
              description: "Listar aprovações para o cliente",
              rules: {
                required: true,
                name: "approvals",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "approvalDetails",
            component: () =>
              import(
                "@/components/approvals/ApprovalDetails/ApprovalDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes da Aprovação",
              description: "Detalhes da approvação gerada para o cliente",
              rules: {
                required: true,
                name: "approvalDetails",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/transferencias-de-saldo",
        name: "balanceTransfers",
        redirect: { name: "balanceTransfersList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/balanceTransfers.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "balanceTransfersList",
            component: () =>
              import(
                "@/components/balanceTransfers/BalanceTransfersList/BalanceTransfersList.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Listar Transferências de Saldo",
              description: "Listar transferências de saldo para o cliente",
              rules: {
                required: true,
                name: "balanceTransfers",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "balanceTransferCreate",
            component: () =>
              import(
                "@/components/balanceTransfers/BalanceTransferCreate/FormCreateTransfer.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Transferência de Saldo",
              description: "Criar nova transferência de saldo para o cliente",
              rules: {
                required: true,
                name: "balanceTransferCreate",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "balanceTransferDetails",
            component: () =>
              import(
                "@/components/balanceTransfers/BalanceTransferDetails/BalanceTransferDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes da Aprovação",
              description: "Detalhes da approvação gerada para o cliente",
              rules: {
                required: true,
                name: "balanceTransferDetails",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/recargas",
        name: "recharges",
        redirect: { name: "rechargesList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/recharges.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "rechargesList",
            component: () =>
              import("@/components/recharges/rechargesList/rechargesList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Recargas",
              description: "Listar recargas para o cliente",
              rules: {
                required: true,
                name: "rechargesList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "rechargeDetails",
            component: () =>
              import(
                "@/components/recharges/rechargeDetails/rechargeDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes do Pedido",
              description: "Detalhes do pedidos gerado para o cliente",
              rules: {
                required: true,
                name: "orderDetails",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/embossing",
        name: "embossing",
        redirect: { name: "embossingList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/embossing.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "embossingList",
            component: () =>
              import("@/components/embossing/embossingList/embossingList.vue"),
            meta: {
              requiresAuth: true,
              title: "Listar Embossings",
              description: "Listar embossings para o cliente",
              rules: {
                required: true,
                name: "embossingList",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/detalhes",
            name: "embossingDetails",
            component: () =>
              import(
                "@/components/embossing/embossingDetails/embossingDetails.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Detalhes do Embossing",
              description: "Detalhes do embossing gerado para o cliente",
              rules: {
                required: true,
                name: "embossingDetails",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "embossingCreation",
            component: () =>
              import(
                "@/components/embossing/embossingCreation/embossingCreation.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Embossing",
              description: "Criar novo embossing para o cliente",
              rules: {
                required: true,
                name: "embossingCreation",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        redirect: { name: "userHistory" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/users.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "userHistory",
            name: "userHistory",
            component: () =>
              import("@/components/users/userHistory/UserHistory.vue"),
            meta: {
              requiresAuth: true,
              title: "Histórico do Usuário",
              description: "Exibe o histórico do usuário",
              rules: {
                required: true,
                name: "userHistory",
                redirect: "home",
              },
            },
          },
          {
            path: ":document",
            name: "userHistoryByDocument",
            component: () =>
              import("@/components/users/userHistory/UserHistory.vue"),
            meta: {
              requiresAuth: true,
              title: "Histórico do Usuário",
              description:
                "Exibe o histórico do usuário com o documento que está na url",
              rules: {
                required: true,
                name: "userHistory",
                redirect: "userHistory",
              },
            },
          },
        ],
      },
      {
        path: "/conta",
        name: "account",
        redirect: { name: "accountBalance" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/account.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "saldo",
            name: "accountBalance",
            meta: {
              requiresAuth: true,
              title: "Saldo",
              description: "Saldo da conta",
              rules: {
                required: true,
                name: "accountBalance",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/operacional",
        name: "operational",
        redirect: { name: "operationalList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/operational.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "operationalList",
            meta: {
              requiresAuth: true,
              title: "Lista de Operacional",
              description: "Listagem de operacional no sistema",
              rules: {
                required: true,
                name: "operationalList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/financeiro",
        name: "financial",
        redirect: { name: "financialList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/financial.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "financialList",
            meta: {
              requiresAuth: true,
              title: "Lista de Financeiro",
              description: "Listagem de financeiro no sistema",
              rules: {
                required: true,
                name: "financialList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "cargas",
        name: "load",
        redirect: { name: "loadList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("@/views/recharge.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista/financeiro",
            name: "RechargeListFinancial",
            component: () =>
              import("@/components/recharge/ListRechargeFinancial.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Cargas",
              description: "Listagem de cargas no sistema",
              rules: {
                required: true,
                name: "loadList",
                redirect: "home",
              },
            },
          },
          {
            path: "lista/operacional",
            name: "RechargeListOperational",
            component: () =>
              import("@/components/recharge/ListRechargeOperational.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Cargas",
              description: "Listagem de cargas no sistema",
              rules: {
                required: true,
                name: "loadList",
                redirect: "home",
              },
            },
          },
          {
            path: "lista/nao-desbloqueados",
            name: "RechargesNotUnlocked",
            component: () =>
              import("@/components/recharge/RechargesNotUnlocked.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Não Desbloqueados",
              description: "Listagem de Não Desbloqueados no sistema",
              rules: {
                required: true,
                name: "loadList",
                redirect: "home",
              },
            },
          },
          {
            path: "operacional/criar",
            name: "loadOperationalCreate",
            component: () =>
              import("@/components/recharge/FormRechargeOperational.vue"),
            meta: {
              requiresAuth: true,
              title: "Criar Carga Operacional",
              description: "Criar carga operacional no sistema",
              rules: {
                required: true,
                name: "loadOperationalCreate",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "pedidos-sem-nota",
        name: "NonInvoiceOrders",
        redirect: { name: "NonInvoiceOrdersList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("@/views/nonInvoiceOrders.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "NonInvoiceOrdersList",
            component: () =>
              import("@/components/nonInvoiceOrders/TableList.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Pedidos sem Nota",
              description: "Listagem de pedidos sem nota no sistema",
              rules: {
                required: true,
                name: "NonInvoiceOrdersList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "relatorios",
        name: "reports",
        redirect: { name: "ordersList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("@/views/ordersReport.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista/pedidos",
            name: "OrdersReportList",
            component: () =>
              import("@/components/reports/ListOrdersReport.vue"),
            meta: {
              requiresAuth: true,
              title: "Listagem dos relatórios de pedidos",
              description: "Listagem de cargas no sistema",
              rules: {
                required: true,
                name: "OrdersReportList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/relatorio-cartoes",
        name: "cardsReport",
        redirect: { name: "cardsReportList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/cardsReport.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "cardsReportList",
            component: () =>
              import("@/components/cardsReport/ListCardsReport.vue"),
            meta: {
              requiresAuth: true,
              title: "Lista de Relatório de Cartões",
              description: "Listagem de relatório de cartões",
              rules: {
                required: true,
                name: "cardsReportList",
                redirect: "home",
              },
            },
          },
        ],
      },
      {
        path: "/contestacoes",
        name: "contestation",
        redirect: { name: "contestationList" },
        components: {
          appHeader: AppHeader,
          appSidebar: SideBarMenu,
          appContent: () => import("../views/contestation.vue"),
          appFooter: AppFooter,
        },
        children: [
          {
            path: "lista",
            name: "contestationList",
            component: () =>
              import(
                "@/components/contestation/ContestationList/TableList.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Lista de Contestações",
              description: "Listagem de contestações",
              rules: {
                required: true,
                name: "contestationList",
                redirect: "home",
              },
            },
          },
          {
            path: "criar",
            name: "contestationCreation",
            component: () =>
              import(
                "@/components/contestation/ContestationCreation/ContestationCreation.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Criar Contestação",
              description: "Criar nova constestação para o cliente",
              rules: {
                required: true,
                name: "contestationCreation",
                redirect: "home",
              },
            },
          },
          {
            path: ":id/editar",
            name: "contestationEdit",
            component: () =>
              import(
                "@/components/contestation/ContestationEdition/ContestationEdition.vue"
              ),
            meta: {
              requiresAuth: true,
              title: "Editar Contestação",
              description: "Editar dados da contestação do cliente",
              rules: {
                required: true,
                name: "contestationEdit",
                redirect: "home",
              },
            },
          },
        ],
      },
    ],
  },
];

export default routes;
